<template>
  <ErrorBlock />
  <DesignPage />

  <FloatingContainer :bottom="'24px'" :right="'24px'">
    <FloatingButton
      :label="'Annuler'"
      :type="'cancel'"
      :symbol="'close'"
      @click="$router.go(-1)"
    />

    <FloatingButton
      class="validate-button"
      :label="'Valider'"
      :type="'validate'"
      :symbol="'done'"
      @click="update"
    />
  </FloatingContainer>
</template>

<script>
import DesignPage from "@/components/DesignPage";
import FloatingContainer from "@/components/FloatingContainer";
import FloatingButton from "@/components/basics/FloatingButton";
import ErrorBlock from "@/components/ErrorBlock";

export default {
  name: "subtableAdd",
  components: { DesignPage, FloatingContainer, FloatingButton, ErrorBlock },
  computed: {
    tableName() {
      return this.$route.meta.tableName;
    },
    url() {
      return this.$route.meta.url;
    },
    linkTableUrl() {
      return this.$route.meta.linkTableUrl;
    },
    fieldId() {
      return this.$route.params.id;
    },
    routeName() {
      return this.$route.name;
    },
    apiData() {
      return this.$store.state.api.data;
    },
    apiIndex() {
      return this.$route.meta.apiIndex;
    },
    firstTable() {
      return this.$route.meta.firstTable;
    },
    secondTable() {
      return this.$route.meta.secondTable;
    },
  },
  methods: {
    update() {
      const data = this.diffBetweenOriginal();

      if (data.toAdd.length) {
        this.$store.dispatch("api/create", {
          tableName: this.linkTableUrl,
          data: this.removeExtraData(data.toAdd),
        });
      }

      if (data.toDelete) {
        let toDeleteIds = [];

        for (var i in data.toDelete) {
          toDeleteIds.push(this.getLinktableId(data.toDelete[i]));
        }

        if (toDeleteIds.length) {
          this.$store.dispatch("api/delete", {
            url: this.linkTableUrl,
            id: toDeleteIds.join(","),
          });
        }
      }

      this.$router.go(-1);
    },
    getOriginalCheckboxData(apiIndex) {
      return this.$store.getters["api/getOriginalCheckboxDataByApiIndex"](
        apiIndex
      );
    },
    getCheckboxData(apiIndex) {
      return this.$store.getters["api/getCheckboxDataByApiIndex"](apiIndex);
    },
    diffBetweenOriginal() {
      const data = this.getCheckboxData(this.apiIndex);
      const originalData = this.getOriginalCheckboxData(this.apiIndex);
      let toAdd = [];
      let toDelete = [];

      for (var i in data) {
        if (!originalData.find((el) => el.id == data[i].id)) {
          toAdd.push({
            [`${this.firstTable}Id`]: parseInt(this.fieldId),
            [`${this.secondTable}Id`]: data[i].id,
          });
        }
      }

      for (var j in originalData) {
        if (!data.find((el) => el.id == originalData[j].id)) {
          toDelete.push(originalData[j]);
        }
      }

      return { toAdd: toAdd, toDelete: toDelete };
    },

    getLinktableId(elem) {
      for (let index in elem) {
        if (Array.isArray(elem[index])) {
          const includeTableData = elem[index][0];

          for (let subIndex in includeTableData) {
            if (
              typeof includeTableData[subIndex] === "object" &&
              includeTableData[subIndex] !== null
            ) {
              return includeTableData[subIndex].id;
            }
          }
        }
      }

      return -1;
    },
    removeExtraData(data) {
      for (let i in data) {
        for (let key in data[i])
          if (Array.isArray(data[i][key])) {
            delete data[i][key];
          }
      }

      return data;
    },
  },
  async created() {
    await this.$store.dispatch("design/getDesignData", this.routeName);

    await this.$store.dispatch("design/refreshActiveFields");
  },
};
</script>

<style lang="less" scoped>
.validate-button {
  margin-left: 10px;
}
</style>
